import React from 'react'
import Helmet from 'react-helmet'

import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Img from 'gatsby-image'

import Layout from 'src/components/layout'
import Row from 'src/components/row'
import CTA from 'src/components/cta'

export default ({ data }) => (
  <Layout>
    <div className="content">
      <Helmet>
        <title>
          Commercial Plubming & Drain - {data.site.siteMetadata.title}
        </title>
      </Helmet>
      <Slider
        autoplay
        speed={500}
        slidesToScroll={1}
        slidesToShow={3}
        arrows={false}
        variableWidth
        adaptiveHeight
        responsive={[{breakpoint: 480, settings: {slidesToShow: 1}}]}
      >
        {data.images.edges.map(({node: { childImageSharp: { fixed } }}) => (
          <div key={fixed.src}>
            <Img fixed={fixed}  style={{margin: "10px"}}/>
          </div>
        ))}
      </Slider>

      <div>
        <h1>Commercial Plumbing Services</h1>
        <h2>Plumbing Projects</h2>
        <p>
          Since 1974 arc has completed hundreds of commercial plumbing
          projects including new construction, renovations, maintenance, or
          upgrades on offices, retailers, factories, service providers, and
          other commercial locations. This includes renovating high-volume food
          service outlets while ensuring that the store or restaurant can
          continue operating while being renovated. Our work includes
          stand-alone buildings, inline units in malls and power centres, and
          kiosks.
        </p>
        <h2>Plumbing and Drain Services</h2>
        <p>
          arc is also your full-line plumbing and drain service provider.
          With over 47 years of experience we know how to handle your
          plumbing or drain problem quickly and thoroughly.
        </p>

        <Slider
          autoplay
          speed={500}
          slidesToScroll={1}
          slidesToShow={2}
          rows={2}
          slidesPerRow={2}
          arrows={false}
          responsive={[{breakpoint: 480, settings: {slidesToShow: 1}}]}
        >
          {data.logos.edges.map(({ node: { childImageSharp: { fixed } } }) => (
            <div key={fixed.src}>
              <Img fixed={fixed}  style={{margin: "10px"}}/>
            </div>
          ))}
        </Slider>

        <h2>Our Services</h2>
        <Row columns={2}>
          <div>
            <h3>
              Drain camera inspection and Location
            </h3>
            <p>
              View inside drain pipes to determine interior conditions and
              investigate ongoing and repeated drain clogs. The camera can help
              provide insight if something is in need of attention inside a drain
              line. <a href="https://youtu.be/75nRbttg6Xw ">
                https://youtu.be/75nRbttg6Xw
              </a> <a href="https://youtu.be/pOQ6gCU4uwo">
                https://youtu.be/pOQ6gCU4uwo
              </a>
            </p>
          </div>
          <div>
            <h3>
              Drain clearing and repair
            </h3>
            <p>
              We unclog drains. Clearing grease, sludge, sediment, debris, roots,
              fixing broken drain pipes, backfall and sagging drains. We also
              excavate and perform repairs to piping.
            </p>
          </div>
          <div>
            <h3>
              Drain jetting/powerflushing
            </h3>
            <p>
              The ultimate in unblocking and clearing drain lines, we use an
              Industrial High flow 3000 psi trailer jetter washer to scour
              interiors of pipes clean and clear of problems.
              https://youtu.be/nFpiqNh5oJc
            </p>
          </div>
          <div>
            <h3>Plumbing for remodelling, renovation, or new construction</h3>
            <p>
              We rough in plumbing for renovations of existing buildings and also
              perform new construction plumbing.
            </p>
          </div>
          <div>
            <h3>Water service upgrades and repair</h3>
            <p>
              Replacement of private water line, repairs to sections of leaking
              water mains, main water valves and repair of burst water mains.
            </p>
          </div>
          <div>
            <h3>Fix low water pressure</h3>
            <p>
              Replace clogging and plugged up low water pressure pipes. Booster
              pump installations.
            </p>
          </div>
          <div>
            <h3>Backwater valves</h3>
            <p>
              Sewer backwater valves installed.  These are devices that may help
              reduce flooding issues when sewer back-up occurs.  Rebates from
              city may apply.
            </p>
          </div>
          <div>
            <h3>Sediment and Oil Interceptors, Grease traps installation</h3>
            <p>
              We install and replace interceptors such as failing grease traps
              and sediment interceptors.  We install oil interceptors for auto
              garages, car washes and parking lots.
            </p>
          </div>
          <div>
            <h3>Diagnose and repair leaks</h3>
            <p>
              Troubleshooting leaks and repairing water and drain lines such as
              burst pipes, rusty piping, drips, leaky connections.
            </p>
          </div>
          <div>
            <h3>Dishwashers, sinks, emergency eye wash, and kitchen equipment</h3>
            <p>
              Commercial kitchen plumbing and commercial dishwasher
              installations.  Barrel washer installations. Three compartment
              kitchen sinks, mop sinks, drinking fountains, touch free faucets,
              floor drains and plumbing installations.
            </p>
          </div>
          <div>
            <h3>Plumbing for manufacturing, process piping, industrial plumbing</h3>
            <p>
              Piping for custom needs in manufacturing, process water, and
              wastewater treatment. Often we use PVC piping. We’ve worked with
              platers, cosmetic manufacturers, breweries
            </p>
          </div>
          <div>
            <h3>Parking lot drain lines</h3>
            <p>
              We replace failing cast iron drain lines in parking lot
              structures at malls, hotels, and large buildings.
            </p>
          </div>
          <div>
            <h3>Pumps, Sewage and Storm Water Pits</h3>
            <p>
              We service pump pits, replacing broken pumps, replace pump floats,
              fix clogged pump lines, broken and burst pump piping repairs.
            </p>
          </div>
          <div>
            <h3>Commercial Roof Storm Drains</h3>
            <p>
              We add, alter, repair roof drains for storm water lines. Add or
              repair storm rainwater leader drain lines and cleanouts.
            </p>
          </div>
          <div>
            <h3>Trench drains, area drains, parking lot drains and commercial and industrial drains</h3>
            <p>
              Plumbing new trench drains, area drains, drain grates as well as
              adding new sediment and oil interceptors to comply with code.
            </p>
          </div>
          <div>
            <h3>Repair frozen pipes</h3>
            <p>
              Blocked frozen pipe thawing, burst frozen pipe repairs, frozen
              garden hose valve repairs, heat tracing water lines.
            </p>
          </div>
        <div>
          <h3>Preventative maintenance</h3>
          <p>
            Preventative maintenance like descaling a hot water heater,
            irrigation system winterizing and startup, performing annual
            service on a backwater valve, performing annual backflow preventer
            testing, inspection, and maintenance
          </p>
        </div>
          <div>
            <h3>Backflow preventer testing, sales, installation and maintenance</h3>
            <p>
              Supply and installation of backflow prevention devices. We perform
              annual testing, tagging and repair backflow preventers. We employ
              Licensed and certified OWWA cross connection control specialists, and
              provide customers with backflow preventer test
              reports <a href="https://youtu.be/Xk76PAbgRuE">
                https://youtu.be/Xk76PAbgRuE
              </a>
            </p>
          </div>
          <div>
            <h3>Cross-connection surveys</h3>
            <p>
              ARC Plumbing has already Completed the Application of Qualified
              Person Registry.  We are registered with the Region of Peel and
              other areas in the GTA and beyond.
            </p>
            <p>
              We can perform an initial Cross Connection Survey by a OWWA
              certified, authorized person, to identify if and where you need
              backflow prevention on water lines at your premises. We help building
              owners and partners ensure compliance towards By-law 10-2017. Contact
              us below to book a Cross Connection Survey or call immediately for
              more information.
            </p>
          </div>
        </Row>

      </div>
    </div>
    <CTA />
    <div className="content">
      <div>

        <h2>Areas We Serve</h2>
        <p>
          arc Plumbing is fully licensed and ready to serve you in
        </p>
        <Row columns={2}>
        <ul>
          <li>Etobicoke</li>
          <li>Long Branch</li>
          <li>New Toronto</li>
          <li>Mimico</li>
          <li>Islington</li>
          <li>Richview</li>
          <li>Sunnylea</li>
          <li>The Westway</li>
          <li>Norseman Heights</li>
          <li>Bloor West Village</li>
          <li>Swansea</li>
          <li>High Park</li>
          <li>Roncesvalles</li>
          <li>Parkdale</li>
          <li>Humberlea</li>
          <li>Caledon</li>
          <li>Brampton</li>
          <li>Mississauga</li>
          <li>Cooksville</li>
          <li>Clarkson</li>
          <li>Mineola</li>
        </ul>
        <ul>
          <li>Lakeview</li>
          <li>Port Credit</li>
          <li>Applewood</li>
          <li>Erindale</li>
          <li>Lisgar</li>
          <li>East Credit</li>
          <li>York Region</li>
          <li>Amesbury</li>
          <li>Meadowvale</li>
          <li>Streetsville</li>
          <li>Oakville</li>
          <li>Eastlake</li>
          <li>Bronte Village</li>
          <li>Old Oakville</li>
          <li>Glen Abbey</li>
          <li>College Park</li>
          <li>Iroquois Ridge</li>
          <li>River Oak</li>
          <li>Milton</li>
          <li>Halton Hills</li>
        </ul>
        </Row>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query CommercialPlumbingQuery {
    site {
      siteMetadata {
        title
      }
    }

    images: allFile(filter: { relativeDirectory: { regex: "/commercial-slideshow/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(height: 300, fit: INSIDE) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }

    logos: allFile(filter: { relativeDirectory: { regex: "/commercial-logos/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(height: 100, width: 150, fit: CONTAIN, grayscale: true, background: "rgba(1,1,1,0)", toFormat: PNG) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
